import React, { useContext } from 'react';
import TopBar from '../top-bar';
import CreateNewMindsweep from './create-new-mindsweep';
import { Link, useParams } from 'react-router-dom';
import { UserContext } from '../../../database/context';
import MindSweep from '../mind-sweep';
import MindSweeps from '../mind-sweeps';
import { useInboxMindSweeps } from '../../../database/mind-sweeps/read';


function Home() {
  const { mindSweepKey } = useParams();
  const { user: { uid } } = useContext(UserContext);
  const mindSweepsQuery = useInboxMindSweeps({ uid });


  return (
    <div className="flex-1 w-full h-full flex text-gray-700 xl:bg-teal-700 xl:p-10">
      <div className="flex-1 w-full h-full flex xl:bg-manatee-100 xl:shadow-lg">
        <div
          className={
            !mindSweepKey
              ? 'w-full flex-shrink-0 xl:w-2/5 xl:max-w-xl flex flex-col relative xl:border-r'
              : 'xl:w-2/5 flex-shrink-0 xl:max-w-xl hidden xl:flex flex-col relative xl:border-r'
          }
        >
          <div className="flex-shrink-0">
            <TopBar />
          </div>
          <div className="overflow-auto scrolling-touch px-3 pt-3">
            <div>
              <div className="flex justify-between items-center my-3 pb-1 border-b">
                <div className="font-bold">Inbox</div>
                <Link
                  className="flex items-center"
                  to="/archive"
                >
                  Archive <i className="ml-2 fal fa-long-arrow-right"></i>
                </Link>
              </div>
              <MindSweeps
                mindSweepsQuery={mindSweepsQuery}
                mindSweepKey={mindSweepKey}
                page="inbox"
              />
            </div>
          </div>
          <div className="fixed xl:hidden bottom-0 right-0">
            <CreateNewMindsweep />
          </div>
        </div>
        {
          !!mindSweepKey
          && (
            <div className="w-full flex-grow flex flex-col">
              <MindSweep />
            </div>
          )
        }
        {
          !mindSweepKey
          && (
            <div className="w-full flex-grow hidden xl:flex flex-col">
              <div className="flex items-center justify-center h-full bg-teal-800">
                <CreateNewMindsweep />
              </div>
            </div>
          )
        }
      </div>
    </div>
  )
}


export default Home;
