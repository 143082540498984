import React from 'react';

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';

import logoYellow from '../images/logo-yellow.svg';

// import VerifyEmail from './verifyEmail';
// import SignInForm from './signInForm';

import MockupIMage from './mockup.png';

function SignIn() {

  const uiConfig = {
    signInFlow: 'popup',
    signInSuccessUrl: '/',
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    ],
  };

  return (
    <div className="flex-1 w-full h-full flex text-gray-700 xl:bg-teal-700 xl:p-10 overflow-auto">
      <div className="relative flex-1 w-full h-full flex flex-col xl:flex-row xl:bg-manatee-100 xl:shadow-lg">

        <div className="flex flex-grow p-10 w-full flex-grow flex-col justify-around bg-teal-800 items-center justify-center h-full">

          <div className="p-5 text-center">
            <img className="mx-auto block h-20" src={logoYellow} alt="logo" />
            <div className="text-white text-2xl mt-5 logo-font block" style={{ fontWeight: '500' }}>
              Brain Messenger
            </div>
          </div>

          <div className="w-full p-5">
            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
            <div className="text-xs text-gray-600 text-center max-w-sm mx-auto mt-2">
              If you have signed up with a personal email link,<br/> please sign up with e-mail and password.
            </div>
            <div className="text-xs text-gray-600 text-center max-w-sm mx-auto mt-2">
              For support or questions please e-mail:<br/> <a href="mailto:oscar@brainmessenger.com">oscar@brainmessenger.com</a>
            </div>
          </div>

        </div>

        <div className="w-full flex items-center content-center diagonal-split-background justify-center h-full">
          <div className="p-2 xl:p-10 h-full pl-5">
            <img className="block max-h-full" src={MockupIMage} alt="mockup" />
          </div>
        </div>        
      </div>
    </div>
  );
}

export default SignIn;
