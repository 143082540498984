import React, { useCallback, useEffect } from 'react';
import { useAuth } from '../database/auth';
import SignIn from './sign-in';
import UserApp from './user-app';
import { UserContext } from '../database/context';


function App() {

  const handleVV = useCallback(event => {
    // const viewport = event.target;
    const keyboardHeight = window.innerHeight - window.visualViewport.height;
    const newHeight = window.innerHeight - keyboardHeight;

    /*
    console.log({
      'viewport.height': viewport.height,
      'window.innerHeight': window.innerHeight,
      'keyboardHeight': keyboardHeight,
      'newHeight': newHeight,
    });
    */

    document.body.style.height = `${newHeight}px`;
    window.scrollTo(0, 0);
  }, []);


  const handleTouch = useCallback(event => {
    window.scrollTo(0, 0);
  }, []);


  useEffect(() => {
    document.addEventListener('touchstart', handleTouch);
    document.addEventListener('touchmove', handleTouch);
    document.addEventListener('touchcancel', handleTouch);
    document.addEventListener('touchend', handleTouch);
    document.addEventListener('scroll', handleTouch);
    
    if (!window.visualViewport) {
      return;
    }

    window.visualViewport.addEventListener('resize', handleVV);

    return () => {
      document.removeEventListener('touchstart', handleTouch);
      document.removeEventListener('touchmove', handleTouch);
      document.removeEventListener('touchcancel', handleTouch);
      document.removeEventListener('touchend', handleTouch);
      document.removeEventListener('scroll', handleTouch);

      window.visualViewport.removeEventListener('resize', handleVV);
    };
  }, [handleVV, handleTouch]);

  const {
    initializing,
    user,
  } = useAuth();

  if (initializing === true) {
    return (
      <div className="mt-10 text-gray-500 text-center">
        <i className="fas fa-cog fa-spin" />
      </div>
    );
  }

  if (!user || !user.uid) {
    return (
      <SignIn />
    );
  }

  return (
    <UserContext.Provider value={{ user }}>
      <UserApp />
    </UserContext.Provider>
  )
}

export default App;
