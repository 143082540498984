import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../../database/context';
import { useMessages } from '../../../database/messages/read';
import Message from './message';


function Messages(props) {
  const { setQListModalOpen } = props;
  
  const { mindSweepKey } = useParams();
  const { user: { uid } } = useContext(UserContext);
  const messagesQuery = useMessages({ uid, mindSweepKey });
  const { loading, data: messages } = messagesQuery;

  
  // > Scrolling
  const [messagesEndRef, setMessagesEndRef] = useState(null);
  const onRefChange = useCallback(node => {
    setMessagesEndRef(node);
  }, []);
  const scrollToBottom = useCallback(() => {
    if(!messagesEndRef) {
      return;
    }
    messagesEndRef.scrollIntoView();
  }, [messagesEndRef]);
  // Scroll to bottom - on change messages (or ref)
  useEffect(scrollToBottom, [messagesEndRef, messages]);
  // Scroll to bottom - on resize
  React.useEffect(() => {
    window.addEventListener('resize', scrollToBottom);
    return () => {
      window.removeEventListener('resize', scrollToBottom);
    };
  }, [scrollToBottom]);
  // < Scrolling


  if (loading) {
    return (
      <div className="flex-grow my-10 text-gray-500 text-center">
        <i className="fas fa-cog fa-spin" />
      </div>
    );
  }

  if (!messages || !messages.length) {
    return (
      <div className="overflow-auto flex-grow scrolling-touch px-3 pt-3 flex justify-center items-center">
        <div className="text-center border-2 border-dashed p-5 rounded-lg text-gray-600">
          <div className="text-xl">Writers block?</div>
          <button
            className="mt-3 bg-gray-700 text-white px-2 py-1 rounded focus:outline-none"
            onClick={() => {
              setQListModalOpen(true);
            }}
          >
            <i className="mr-2 far fa-lightbulb-on"></i>
            Some inspiration for your thoughts
          </button>
        </div>
      </div>
    )
  }

  return (
    <div
      className="overflow-auto flex-grow scrolling-touch px-3 pt-3"
    >

      <div className="mt-5" style={{ minHeight: '5em' }}>
        {
          !!messages
          && !!messages.length
          && messages.map((message, i) => {
            const { key } = message;
            return (
              <Message
                key={key}
                message={message}
                isLastMessage={messages.length === (i + 1)}
              />
            );
          })
        }
      </div>
      <div ref={onRefChange} />
    </div>
  )
}

export default Messages;
