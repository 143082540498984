import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/analytics';


export const config = {
  apiKey: 'AIzaSyD3gxby0WyHhAblNUdoFZN_EI3loi3nXRY',
  authDomain: 'brain-messenger.firebaseapp.com',
  databaseURL: 'https://brain-messenger.firebaseio.com',
  projectId: 'brain-messenger',
  storageBucket: 'brain-messenger.appspot.com',
  messagingSenderId: '4949865936',
  appId: '1:4949865936:web:773437a12a94d4c5aff5d5',
  measurementId: 'G-212677348',
}

firebase.initializeApp(config);

firebase.firestore().settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
});
firebase.firestore().enablePersistence();

firebase.analytics();

export const fb = firebase;
export const db = firebase.firestore();
export const functions = firebase.functions();