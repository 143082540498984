import React from 'react';


export const UserContext = React.createContext({
  user: null,
});

export const UserDataContext = React.createContext({
  userData: null,
});

export const CurrentMindSweepContext = React.createContext([null, null]);


