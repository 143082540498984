import React from 'react';
import NavigationAction from './navigation-action.png';

function PWAInstallInstructions(props) {
  const modalNode = React.useRef();
  const { setShowInstructions } = props;

  document.body.classList.add('overflow-hidden');

  React.useEffect(() => {
    const handleClick = (e) => {
      if (modalNode.current.contains(e.target)) {
        return;
      }
      document.body.classList.remove('overflow-hidden');
      setShowInstructions(false);
    };

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
      document.body.classList.remove('overflow-hidden');
    };
  }, [setShowInstructions]);

  return (
    <div
      className="fixed overflow-auto w-full h-full inset-0 z-50 flex flex-col justify-end xl:justify-center items-center xl:items-center px-10 z-50"
      style={{ background: 'rgba(0,0,0,0.7)' }}
    >
      <div
        ref={modalNode}
        className="rounded-lg shadow w-full xl:w-1/2 mx-auto"
      >
        <div className="bg-teal-700 text-white rounded-t-lg p-5 text-lg font-bold">
          Add Brain Messenger to your homescreen for the best experience
        </div>
        <div className="rounded-b-lg bg-white text-gray-700 p-5 text-sm xl:text-base">
          <div className="mb-5">
            <h2 className="mb-2 font-bold">Install app on iOS</h2>
            <ol className="list-decimal list-inside">
              <li>
                Tap the action icon <img className="inline-block" src={NavigationAction} alt="iOS action button"/> on the bottom of the Safari window.
              </li>
              <li>Tap "Add to homescreen".</li>
            </ol>
          </div>
          <div>
            <h2 className="mb-2 font-bold">Install app on Android</h2>
            <ol className="list-decimal list-inside">
              <li>Tap the menu icon (3 dots in upper right-hand corner)</li>
              <li>Tap "Add to homescreen".</li>
            </ol>
          </div>
        </div>
      </div>
      <div className="w-full p-5 flex xl:hidden justify-center text-white text-lg">
        <i className="far fa-times"></i>
      </div>
    </div>
  );
}

export default PWAInstallInstructions;
