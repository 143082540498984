import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
import { CurrentMindSweepContext, UserContext } from '../../../database/context';
import { setMindSweep } from '../../../database/mind-sweeps/write';


function EditTitleModal(props) {
  const modalNode = React.useRef();
  const { title, setEditTitle } = props;
  const { user: { uid } } = useContext(UserContext);
  const { data: mindSweep } = useContext(CurrentMindSweepContext);
  const { exists } = mindSweep || {};
  const [input, setInput] = React.useState(title);
  const inputElement = React.useRef(null);
  const { mindSweepKey } = useParams();

  document.body.classList.add('overflow-hidden');


  React.useEffect(() => {
    const handleClick = (e) => {
      if (modalNode.current.contains(e.target)) {
        return;
      }
      document.body.classList.remove('overflow-hidden');
      setEditTitle(false);
    };

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
      document.body.classList.remove('overflow-hidden');
    };
  }, [setEditTitle]);


  const update = () => {
    // update
    console.log({
      input,
      mindSweepKey
    })

    const fields = {
      title: input,
      uid,
    }

    if (!exists) {
      fields.created = Date.now();
      fields.page = 'inbox';
    }
    
    setMindSweep({
      mindSweepKey,
      fields,
    });

    setEditTitle(false);
  }

  return (
    <div
      className="fixed overflow-auto w-full h-full inset-0 z-50 flex flex-col justify-end xl:justify-center items-center xl:items-center px-10 z-50"
      style={{ background: 'rgba(0,0,0,0.7)' }}
    >
      <div
        ref={modalNode}
        className="rounded-lg shadow w-full xl:w-1/2 mx-auto"
      >
        <div>
          <TextareaAutosize
            minRows={1}
            maxRows={3}
            inputRef={inputElement}
            onChange={e => setInput(e.target.value)}
            value={input}
            className={`
              p-5 rounded-t-lg focus:outline-none block w-full appearance-none leading-normal
            `}
            type="text"
            placeholder="Type a message"
            style={{
              resize: 'none'
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && e.shiftKey) {
                return;
              }
              if (e.key === 'Enter') {
                e.preventDefault();
                if (!input || input === title) {
                    return;
                  }
                  update();
                }
            }}
            autoFocus={true}
          />
        </div>
        <div className="flex text-center">
          <button
            onClick={() => {
              setEditTitle(false);
            }}
            className={`
              flex-1 p-3 bg-white text-gray-600 border-t border-r rounded-bl-lg font-bold focus:outline-none
            `}
          >
            Cancel
          </button>
          <button
              type="button"
              onClick={() => {
                if (!input || input === title) {
                  return;
                }
                update();
              }}
              className={`
                flex-1 block py-1 px-2 text-sm rounded-br-lg font-bold focus:outline-none
                ${input === title ? 'bg-white text-gray-400 border-t': 'bg-green-500  hover:bg-green-600 text-white'}
              `}
            >
              Save
            </button>
        </div>
      </div>
      <div className="w-full p-5 flex xl:hidden justify-center text-white text-lg">
        <i className="far fa-times"></i>
      </div>
    </div>
  );
}

export default EditTitleModal;
