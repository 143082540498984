import React, { useContext } from 'react';
import { useUserData } from '../../database/user';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { UserContext, UserDataContext } from '../../database/context';
import Archive from './archive';
import Inbox from './inbox';


function UserApp() {
  const { user } = useContext(UserContext);
  const { loadingUserData, userData } = useUserData(user.uid);


  if (loadingUserData) {
    return (
      <div className="mt-10 text-gray-500 text-center">
        <i className="fas fa-cog fa-spin" />
      </div>
    );
  }


  return (
    <UserDataContext.Provider value={{ userData }}>
      <Router>
        <Switch>
          <Route path="/archive/:mindSweepKey">
            <Archive />
          </Route>
          <Route path="/archive">
            <Archive />
          </Route>
          <Route path="/m/:mindSweepKey">
            <Inbox />
          </Route>
          <Route path="/">
            <Inbox />
          </Route>
        </Switch>
      </Router>
    </UserDataContext.Provider>
  )
}


export default UserApp;
