import { db } from '..';
import { setMindSweep } from '../mind-sweeps/write';

export const updateMessage = ({ messageKey, fields, mindSweep }) => {
  const now = Date.now();
  const { key: mindSweepKey } = mindSweep || {};

  if (
    !mindSweep
    || !mindSweepKey
    || !fields
    || !fields.text
    || !fields.uid
  ) {
    return Promise.reject(new Error('fail'));
  }

  const message = {
    updated: now,
    ...fields,
  }

  return db.collection('messages').doc(messageKey).set(message, { merge: true })
    .then(() => {
      const mindSweepFields = {}

      // Only if the edit is on the last message chage the last message in the Mind Sweep
      if (fields.isLastMessage) {
        mindSweepFields.lastMessage = message;
      }

      // We do keep this function because setMindSweeps updates the update timestamp.
      return setMindSweep({
        mindSweepKey,
        fields: mindSweepFields,
      })
    })
};