import React, { useEffect, useState } from 'react';
import PWAInstallInstructions from './pwa-install-instructions';


function PWAInstall() {

  const [showInstructions, setShowInstructions] = useState(false);
  const [displayMode, setDisplayMode] = useState('browser tab');

  useEffect(() => {
    if (navigator.standalone) {
      setDisplayMode('standalone-ios');
    }
    if (window.matchMedia('(display-mode: standalone)').matches) {
      setDisplayMode('standalone');
    }
  }, [displayMode]);

  if (displayMode === 'browser tab') {
    return (
      <>
        {
          !!showInstructions
          && <PWAInstallInstructions setShowInstructions={setShowInstructions} />
        }
        <button
          type="button"
          className="py-1 px-2 rounded mx-2 text-white bg-salmon-500 leading-none focus:outline-none"
          onClick={() => {
            setShowInstructions(true);
          }}
        >
          install app
        </button>
      </>
    )
  };

  return null;
}


export default PWAInstall;
