import React, { useContext, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { CurrentMindSweepContext, UserContext } from '../../../database/context';
import { useMindSweep } from '../../../database/mind-sweeps/read';
import InputBar from './input-bar';
import Messages from '../messages';
import MindsweepTopBar from './mindsweep-top-bar';
import QuestionList from './question-list';


function MindSweep() {
  const { user } = useContext(UserContext);
  const { uid } = user;
  
  const { mindSweepKey } = useParams();
  const { state } = useLocation();
  const mindSweep = useMindSweep({ mindSweepKey, uid });

  const [qListModalOpen, setQListModalOpen] = useState(false);
  const inputElement = React.useRef(null);
  const [isQuestion, setIsQuestion] = React.useState(false);


  return (
    <CurrentMindSweepContext.Provider value={mindSweep}>
      {
        !!qListModalOpen
        && (
          <QuestionList
            setQListModalOpen={setQListModalOpen}
            inputElement={inputElement}
            setIsQuestion={setIsQuestion}
          />
        )
      }
      <div className="flex-1 flex flex-col text-gray-700 h-full">
        <div className="flex-shrink-0">
          <MindsweepTopBar />
        </div>
        <Messages setQListModalOpen={setQListModalOpen} />
        <div className="flex-shrink-0">
          <InputBar
            qListModalOpen={qListModalOpen}
            setQListModalOpen={setQListModalOpen}
            inputElement={inputElement}
            isQuestion={isQuestion}
            setIsQuestion={setIsQuestion}
            typing={ !!state && !!state.typing }
          />
        </div>
      </div>
    </CurrentMindSweepContext.Provider>
  )
}


export default MindSweep;
