import React, { useState } from 'react';
import MessageModal from './message-modal';


function Message(props) {
  const { message, isLastMessage } = props;
  const { key, text, question, strikethrough } = message;
  const [selected, setSelected] = useState(false);


  return (
    <div
      key={key}
      className={`
      mb-2 flex
        ${!!question ? 'justify-start items-start mr-12': 'justify-end items-start ml-12'}
      `}
      style={{ wordBreak: 'break-word' }}
    >
      <button
        className={`
          rounded p-3 inline-block text-left leading-none border-2 border-transparent focus:outline-none
          ${!!question ? 'text-white bg-gray-700 hover:border-gray-800': 'text-gray-800 bg-gray-300 hover:border-gray-400'}
          ${!!strikethrough ? 'line-through': ''}
        `}
        onClick={() => {
          setSelected(true);
        }}
      >
        { text }
      </button>

      {
        !!selected
        && (
          <MessageModal message={message} setSelected={setSelected} isLastMessage={isLastMessage} />
        )
      }
    </div>
  );
}

export default Message;
