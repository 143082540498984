import React from 'react';
import firebase from 'firebase/app';


// Read
export function useUserData(uid) {
  const [error, setError] = React.useState(false);
  const [loadingUserData, setLoadingUserData] = React.useState(true);
  const [userData, setUserData] = React.useState(null);

  React.useEffect(
    () => {
      const unsubscribe = firebase
        .firestore()
        .collection('users')
        .doc(uid)
        .onSnapshot((doc) => {
          const data = doc.data();
          setUserData({
            uid,
            ...data,
          });
          setLoadingUserData(false);
        }, (err) => {
          setError(err);
        });
      return () => unsubscribe();
    },
    [uid],
  );

  return {
    error,
    loadingUserData,
    userData,
  };
}