import React, { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import TopBar from '../top-bar';
import MindSweeps from '../mind-sweeps';
import { UserContext } from '../../../database/context';
import MindSweep from '../mind-sweep';
import { useArchivedMindSweeps } from '../../../database/mind-sweeps/read';


function Archive() {
  const { mindSweepKey } = useParams();
  const { user } = useContext(UserContext);
  const mindSweepsQuery = useArchivedMindSweeps({ uid: user.uid });


  return (
    <div className="flex-1 w-full h-full flex text-gray-700 xl:bg-teal-700 xl:p-10">
      <div className="flex-1 w-full h-full flex xl:bg-manatee-100 xl:shadow-lg">
        <div
          className={
            !mindSweepKey
              ? 'w-full xl:w-2/5 xl:max-w-xl flex-shrink-0 flex flex-col relative xl:border-r'
              : 'xl:w-2/5 xl:max-w-xl flex-shrink-0 hidden xl:flex flex-col relative xl:border-r'
          }
        >
          <div className="flex-shrink-0">
            <TopBar />
          </div>
          <div className="w-full overflow-auto scrolling-touch px-3 pt-3">
            <div className="w-full">
              <div className="flex justify-between items-center my-3 pb-1 border-b">
                <Link
                  className="flex items-center"
                  to="/"
                >
                  <i className="fal fa-long-arrow-left mr-2"></i>
                  Inbox
                </Link>
                <div className="font-bold">
                  Archive
                </div>
              </div>
              <MindSweeps
                mindSweepsQuery={mindSweepsQuery}
                mindSweepKey={mindSweepKey}
                page="archive"
              />
            </div>
          </div>
        </div>

        {
          !!mindSweepKey
          && (
            <div className="w-full flex-grow flex flex-col">
              <MindSweep />
            </div>
          )
        }
        {
          !mindSweepKey
          && (
            <div className="w-full flex-grow hidden xl:flex flex-col">
              <div className="flex items-center justify-center h-full text-gray-400 text-2xl">
              Archive
              </div>
            </div>
          )
        }
      </div>
    </div>
  );
}

export default Archive;
