import React, { useEffect, useState } from 'react';
import { logout } from '../../../database/auth';
import { Link } from 'react-router-dom';
import logoGreen from '../../images/logo-green.svg';
import logoYellow from '../../images/logo-yellow.svg';
import PWAInstall from './pwa';


function TopBar() {
  const [displayMode, setDisplayMode] = useState('browser tab');

  useEffect(() => {
    if (navigator.standalone) {
      setDisplayMode('standalone-ios');
    }
    if (window.matchMedia('(display-mode: standalone)').matches) {
      setDisplayMode('standalone');
    }
  }, []);
  
  return (
    <>
      <div>
        <div className="h-12 bg-teal-700 text-white xl:bg-white xl:text-gray-700 flex items-center shadow">
          <Link
            className="tex-left flex items-center pl-2 leading-none focus:outline-none"
            to="/"
          >
            <img className="h-6 hidden xl:block" src={logoGreen} alt="logo"/>
            <img className="h-6 block xl:hidden" src={logoYellow} alt="logo"/>
          </Link>
          <div className="ml-2 text-xs text-gray-500">
            Alpha 0.2.1 - { displayMode }
          </div>
          <div className="ml-auto flex items-center">
            <PWAInstall />
            <button
              type="button"
              className="ml-auto p-4 ml-2 leading-none focus:outline-none"
              onClick={() => {
                logout();
              }}
            >
              <i className="far fa-sign-out"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}


export default TopBar;
