import { db, fb } from '..';

export const setMindSweep = ({ mindSweepKey, fields }) => {
  const now = Date.now();

  fb.analytics().logEvent('create_mind-sweep');

  return db.collection('mind-sweeps').doc(mindSweepKey).set({
    updated: now,
    ...fields,
  }, { merge: true });
};