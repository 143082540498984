import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { deleteMindSweep } from '../../../database/mind-sweeps/delete';
import { setMindSweep } from '../../../database/mind-sweeps/write';


function MindSweeps(props) {
  const history = useHistory();
  const { mindSweepsQuery, mindSweepKey } = props;
  const { loading, data: mindSweeps } = mindSweepsQuery;

  if (loading) {
    return (
      <div className="mt-10 text-gray-500 text-center">
        <i className="fas fa-cog fa-spin" />
      </div>
    );
  }

  return (
    <div className="pb-5">
      {
        !!mindSweeps
        && !!mindSweeps.length
        && mindSweeps.map((mindSweep) => {
          const { key, title, lastMessage, page } = mindSweep;

          return (
            <div
              key={key}
              className={`
                rounded-lg mb-3 shadow
                ${
                  page === 'inbox'
                    ? mindSweepKey === key ? 'text-white bg-denim-700' : 'text-gray-700 bg-white'
                    : ''
                }
                ${
                  page === 'archived'
                    ? mindSweepKey === key ? 'text-white bg-teal-700' : 'text-white bg-gray-700'
                    : ''
                }
              `}
            >
              <Link
                className="block w-full p-3"
                to={`/${page === 'archived' ? 'archive' : 'm'}/${key}`}
              >
                <div className="text-left">
                  {
                    !!title
                    && (
                      <div className="font-bold break-words">{ title }</div>
                    )
                  }
                  <div className="text-sm italic break-words">
                    {
                      !!lastMessage
                      && lastMessage.text
                    }
                  </div>
                </div>
              </Link>
              <div className="flex border-t bg-gray-100 rounded-b-lg text-gray-600 text-sm">
                {
                  page === 'inbox'
                  && (
                    <>
                      <button
                        className="block w-full hover:bg-gray-200 rounded-bl-lg p-2 flex-1 text-center focus:outline-none"
                        onClick={() => {
                          setMindSweep({
                            mindSweepKey: key,
                            fields: {
                              page: 'archived',
                            },
                          })
                          .then(() => {
                            history.push('/');
                          })
                        }}
                      >
                        <i className="far fa-archive mr-2"></i> Archive
                      </button>
                      <div
                        className="block w-full p-2 flex-1 text-center border-l text-gray-300"
                        onClick={() => {
                          // email
                        }}
                      >
                        <i className="far fa-envelope mr-2"></i> Mail to me
                      </div>
                    </>
                  )
                }
                {
                  page === 'archived'
                  && (
                    <>
                      <button
                        className="block w-full hover:bg-gray-200 rounded-bl-lg p-2 flex-1 text-center focus:outline-none"
                        onClick={() => {
                          setMindSweep({
                            mindSweepKey: key,
                            fields: {
                              page: 'inbox',
                            },
                          })
                          .then(() => {
                            history.push('/archive');
                          })
                        }}
                      >
                        <i className="far fa-undo mr-2"></i> Send back to inbox
                      </button>
                      <button
                        className="block w-full hover:bg-gray-200 rounded-br-lg p-2 flex-1 text-center border-l focus:outline-none"
                        onClick={() => {
                          const sure = window.confirm('Are you sure?');
                          if (!sure) {
                            return;
                          }
                          deleteMindSweep({
                            mindSweepKey: key,
                          })
                          .then(() => {
                            history.push('/archive');
                          })
                        }}
                      >
                        <i className="far fa-trash mr-2"></i> Delete
                      </button>
                    </>
                  )
                }
              </div>
            </div>
          )
        })
      }
    </div>
  )
}


export default MindSweeps;
