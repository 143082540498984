import React, { useContext, useState } from 'react';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import TextareaAutosize from 'react-textarea-autosize';
import { CurrentMindSweepContext, UserContext } from '../../../database/context';
import { useParams } from 'react-router-dom';
import { sendDigestMail } from '../../../database/email';
import { createMessage } from '../../../database/messages/create';


function InputBar(props) {
  const {
    setQListModalOpen,
    inputElement,
    isQuestion,
    setIsQuestion,
    typing,

  } = props;
  const { data: mindSweep } = useContext(CurrentMindSweepContext);
  const { user: { uid, email } } = useContext(UserContext);
  const { mindSweepKey } = useParams();
  const [input, setInput] = React.useState('');
  const [sending, setSending] = useState(false);

  const sendMessage = ({
    text,
    question = false,
    email = false,
  }) => {
    if (!text) {
      return;
    }

    const fields = {
      uid,
      mindSweepKey,
      created: Date.now(),
      text,
      question,
      email,
    }

    createMessage({
      messageKey: uuidv4(),
      fields,
      mindSweep,
    })
    .then(() => {
      setSending(false);
    })
    .catch((error) => {
      console.error(error);
    });

    if (isQuestion) {
      setIsQuestion(false);
    }

    setInput('');

    inputElement.current.focus();
  }

  return (
    <div>
      <div className="flex items-end leading-none p-2">
        
        {
          !isQuestion
          && (
            <button
              className="block rounded-full bg-denim-700 hover:bg-denim-800 p-1 flex mr-2 my-2 focus:outline-none"
              onClick={() => {
                setIsQuestion(true);
                inputElement.current.focus();
              }}
            >
              <div className="h-6 w-2 flex justify-center items-center content-center text-gray-400 leading-none"></div>
              <div className="bg-white text-gray-700 text-lg rounded-full h-6 w-6 flex justify-center items-center content-center leading-none shadow font-bold">A</div>
            </button>
          )
        }

        {
          !!isQuestion
          && (
            <button
              className="bg-gray-700 hover:bg-gray-800 rounded-full p-1 flex mr-2 my-2 focus:outline-none"
              onClick={() => {
                setIsQuestion(false);
                inputElement.current.focus();
              }}
            >
              <div className="bg-white text-gray-700 text-lg rounded-full h-6 w-6 flex justify-center items-center content-center leading-none shadow font-bold">?</div>
              <div className="h-6 w-2 flex justify-center items-center content-center text-gray-400 leading-none"></div>
            </button>
          )
        }

        <div
          className="flex-grow bg-white py-3 px-5 shadow relative flex items-stretch"
          style={{
            borderTopLeftRadius: '1rem',
            borderBottomLeftRadius: '1rem',
            borderTopRightRadius: '1rem',
            borderBottomRightRadius: '1rem',
          }}
        >
          <TextareaAutosize
            minRows={1}
            maxRows={3}
            inputRef={inputElement}
            onChange={e => setInput(e.target.value)}
            value={input}
            className="focus:outline-none block w-full appearance-none leading-normal"
            type="text"
            placeholder="Type a message"
            style={{
              resize: 'none'
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && e.shiftKey) {
                return;
              }
              if (e.key === 'Enter') {
                e.preventDefault();
                sendMessage({
                  text: input.replace(/\s*$/,''),
                  question: isQuestion,
                });
              }
            }}
            autoFocus={!!typing}
          />
          {
            (
              !input
              || !input.length
            )
            && (
              <button
                className="h-6 flex flex-col justify-center text-lg focus:outline-none"
                onClick={() => {
                  setQListModalOpen(true);
                }}
              >
                <i className="far fa-lightbulb-on"></i>
              </button>
            )
          }
        </div>
        <div className="flex items-center">
          <button
            type="button"
            className="shadow ml-2 w-12 h-12 bg-denim-700 hover:bg-denim-800 rounded-full flex justify-center items-center content-center focus:outline-none"
            onClick={() => {
              setSending(true);

              // If has text, send message
              if (input && input.length) {
                sendMessage({
                  text: input.replace(/\s*$/,''),
                  question: isQuestion,
                });
                return;
              }

              // No text, send email
              sendDigestMail({ mindSweepKey, email })
                .then(() => {
                  setSending(false);

                  // create message
                  return sendMessage({
                    text: `Digest email sent: ${moment().format('D-MM-YYYY HH:mm')}`,
                    question: false,
                    email: true,
                  });
                })
                .catch((error) => {
                  console.log('Error, contact Oscar.');
                  setSending(false);
                });
            }}
          >
            {
              !!sending
              && (
                <i className="fas fa-spinner-third fa-spin text-white text-lg"></i>
              )
            }
            {
              !!input
              && !sending
              && input.length
              && (
                <i className="fas fa-paper-plane text-white text-lg" />
              )
            }
            {
              (
                !input
                && !sending
                && !input.length
              )
              && (
                <i className="far fa-envelope text-white text-lg"></i>
              )
            }
          </button>
        </div>
      </div>
    </div>
  );
}


export default InputBar;
