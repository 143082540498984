import { functions } from '.';


export function sendDigestMail({ mindSweepKey, email }) {
  const sendDigestMail = functions.httpsCallable('sendDigestMail');
  return sendDigestMail({ mindSweepKey, email });
};

export function sendSignInMail({  email }) {
  const sendSignInMail = functions.httpsCallable('sendSignInMail');
  return sendSignInMail({ email }).then((result) => {
    window.localStorage.setItem('emailForSignIn', email);
  });
};
