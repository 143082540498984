import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
import { CurrentMindSweepContext, UserContext } from '../../../database/context';
import { updateMessage } from '../../../database/messages/update';


function MessageModal(props) {
  const modalNode = React.useRef();
  const {
    message,
    setSelected,
    isLastMessage,
  } = props;
  const {
    key,
    text,
    question = false,
    strikethrough = false,
    hidden = false,
  } = message;
  const { user: { uid, email } } = useContext(UserContext);
  const { data: mindSweep } = useContext(CurrentMindSweepContext);
  const [input, setInput] = React.useState(text);
  const inputElement = React.useRef(null);
  const { mindSweepKey } = useParams();

  document.body.classList.add('overflow-hidden');


  React.useEffect(() => {
    const handleClick = (e) => {
      if (modalNode.current.contains(e.target)) {
        return;
      }
      document.body.classList.remove('overflow-hidden');
      setSelected(false);
    };

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
      document.body.classList.remove('overflow-hidden');
    };
  }, [setSelected]);


  const update = ({ key, text, question, strikethrough, hidden }) => {

    const fields = {
      uid,
      mindSweepKey,
      text,
      question,
      email,
      strikethrough,
      hidden,
      isLastMessage,
    }

    updateMessage({
      messageKey: key,
      fields,
      mindSweep,
    })
    .catch((error) => {
      console.error(error);
    });
  }

  return (
    <div
      className="fixed overflow-auto w-full h-full inset-0 z-50 flex flex-col justify-end xl:justify-center items-center xl:items-center px-10 z-50"
      style={{ background: 'rgba(0,0,0,0.7)' }}
    >
      <div
        ref={modalNode}
        className="rounded-lg shadow w-full xl:w-1/2 mx-auto"
      >
        <div>
          <TextareaAutosize
            minRows={1}
            maxRows={3}
            inputRef={inputElement}
            onChange={e => setInput(e.target.value)}
            value={input}
            className={`
              p-5 rounded-t-lg focus:outline-none block w-full appearance-none leading-normal
              ${!!question ? 'bg-gray-700 text-white': ''}
              ${!!strikethrough ? 'line-through': ''}
            `}
            type="text"
            placeholder="Type a message"
            style={{
              resize: 'none'
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && e.shiftKey) {
                return;
              }
              if (e.key === 'Enter') {
                e.preventDefault();
                if (!input || input === text) {
                    return;
                  }
                  update({ key, text: input, question, strikethrough, hidden });
                }
            }}
          />
        </div>
        <div className="flex text-center">
          <button
            onClick={() => {
              update({ key, text, question: !question, strikethrough, hidden });
            }}
            className={`
              flex-1 p-3 rounded-bl-lg font-bold focus:outline-none
              ${!!question ? 'bg-gray-700 text-white hover:bg-gray-800': 'bg-white hover:bg-gray-200 border-t'}
            `}
          >
            ?
          </button>
          <button
            onClick={() => {
              update({ key, text, question, strikethrough: !strikethrough, hidden });
            }}
            className={`
              flex-1 p-3 focus:outline-none
              ${!!strikethrough ? 'bg-gray-700 text-white hover:bg-gray-800': 'bg-white hover:bg-gray-200 border-t'}
            `}
          >
            <i className="fas fa-strikethrough"></i>
          </button>
          <button
            onClick={() => {
              inputElement.current.select();
            }}
            className="flex-1 border-t p-3 bg-white hover:bg-gray-200 focus:outline-none"
          >
            <i className="far fa-copy"></i>
          </button>
          <button
              type="button"
              onClick={() => {
                if (!input || input === text) {
                  return;
                }
                update({ key, text: input, question, strikethrough, hidden });
              }}
              className={`
                flex-1 block py-1 px-2 text-sm rounded-br-lg font-bold focus:outline-none
                ${input === text ? 'bg-white text-gray-400 border-t': 'bg-green-300  hover:bg-green-400'}
              `}
            >
              save
            </button>
        </div>
      </div>
      <div className="w-full p-5 flex xl:hidden justify-center text-white text-lg">
        <i className="far fa-times"></i>
      </div>
    </div>
  );
}

export default MessageModal;
