import React from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

function CreateNewMindsweep() {
  const history = useHistory();

  return (
    <div className="relative">
      <div className="flex items-end leading-none p-5">
        <div className="flex items-center">
          <button
            className="shadow ml-2 py-4 px-5 bg-denim-700 hover:bg-denim-800 rounded-full flex justify-center items-center content-center focus:outline-none"
            onClick={() => {
              history.push({
                pathname: `/m/${uuidv4()}`,
                state: { typing: true }
              });
            }}
          >
            <span className="mr-3 text-white font-bold">Start new mindsweep</span>
            <i className="fas fa-arrow-right text-white text-lg" />
          </button>
        </div>
      </div>
    </div>
  );
}


export default CreateNewMindsweep;
