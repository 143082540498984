import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { CurrentMindSweepContext, UserContext } from '../../../database/context';
import { createMessage } from '../../../database/messages/create';

function QuestionList(props) {
  const modalNode = React.useRef();
  const { setQListModalOpen, inputElement, setIsQuestion } = props;
  const { data: mindSweep } = useContext(CurrentMindSweepContext);
  const { user: { uid } } = useContext(UserContext);
  const { mindSweepKey } = useParams();

  document.body.classList.add('overflow-hidden');

  const [page, setPage] = useState('professional');
  const questions = {
    professional: [
      'What projects or tasks are on your mind?',
      'What events, meetings, trips, presentations do you have to prepare for?',
      'What open commitments do you have to your partners, colleagues, and customers?',
      'What are you waiting for that is holding you from moving forward?',
      'What do you want to learn or develop professionally?',
    ],
    personal: [
      'Which personal projects or tasks are on your mind?',
      'What events, birthdays, holidays, weddings, parties do you have to prepare for? ',
      'What open commitments do you have to your spouse, partner, children, parents, family, and friends?',
      'Are your personal finances and administration clear?',
      'What fun projects are you dreaming of?',
    ],
    emotional: [
      'How are you feeling, mentally, and physically?',
      'What are you thankful for?',
      'What is bothering you?',
      'What can you change, and what do you have to accept?',
      'What can you do now or today to cheer yourself up?',
    ]
  }


  const sendMessage = ({ text }) => {

    const fields = {
      uid,
      mindSweepKey,
      created: Date.now(),
      text,
      question: true,
    }

    createMessage({
      messageKey: uuidv4(),
      fields,
      mindSweep,
    })
    .catch((error) => {
      console.error(error);
    });

    setQListModalOpen(false);
    setIsQuestion(false);
    inputElement.current.focus();
  }


  React.useEffect(() => {
    const handleClick = (e) => {
      if (modalNode.current.contains(e.target)) {
        return;
      }
      document.body.classList.remove('overflow-hidden');
      setQListModalOpen(false);
    };

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
      document.body.classList.remove('overflow-hidden');
    };
  }, [setQListModalOpen]);

  return (
    <div
      className="fixed overflow-auto w-full h-full inset-0 z-50 flex flex-col justify-end xl:justify-center items-center xl:items-center px-10"
      style={{ background: 'rgba(0,0,0,0.7)' }}
    >
      <div
        ref={modalNode}
        className="rounded-lg shadow w-full xl:w-1/2 mx-auto"
      >
        <div className="bg-teal-700 text-white rounded-t-lg p-5 text-lg font-bold">
          Some inspiration for your thoughts
        </div>
        <div className="bg-white p-5 text-sm xl:text-base">
          <div>
            {
              questions[page].map((question) => (
                <button
                  className="bg-gray-300 hover:bg-gray-400 w-full text-left mt-2 rounded-lg p-2 flex focus:outline-none"
                  onClick={() => {
                    sendMessage({ text: question });
                  }}
                >
                  <div className="flex-grow">{question}</div>
                  <div className="flex-shrink-0"><i className="ml-2 fal fa-long-arrow-right"></i></div>
                </button>
              ))
            }
          </div>
        </div>
        <div className="flex text-center">
          <button
            onClick={() => {
              setPage('professional')
            }}
            className={
              page === 'professional'
                ? 'flex-1 border-t p-3 rounded-bl-lg font-bold bg-denim-700 text-white cursor-default focus:outline-none'
                : 'flex-1 border-t p-3 rounded-bl-lg bg-white hover:bg-gray-200 focus:outline-none'
            }
          >
              Professional
          </button>
          <button
            onClick={() => {
              setPage('personal')
            }}
            className={
              page === 'personal'
                ? 'flex-1 border-t p-3 focus:outline-none font-bold bg-denim-700 text-white cursor-default border-l border-r'
                : 'flex-1 border-t p-3 hover:bg-gray-200 bg-white focus:outline-none border-l border-r'
            }
          >
              Personal
          </button>
          <button
            onClick={() => {
              setPage('emotional')
            }}
            className={
              page === 'emotional'
                ? 'flex-1 border-t p-3 rounded-br-lg font-bold bg-denim-700 text-white cursor-default focus:outline-none'
                : 'flex-1 border-t p-3 rounded-br-lg bg-white hover:bg-gray-200 focus:outline-none'
            }
          >
              Emotional
          </button>
        </div>
      </div>
      <div className="w-full p-5 flex xl:hidden justify-center text-white text-lg">
        <i className="far fa-times"></i>
      </div>
    </div>
  );
}

export default QuestionList;
