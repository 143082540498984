import React, { useContext, useState } from 'react';
import { logout } from '../../../database/auth';
import { CurrentMindSweepContext } from '../../../database/context';
import { Link, useHistory, useParams } from 'react-router-dom';
import { deleteMindSweep } from '../../../database/mind-sweeps/delete';
import { setMindSweep } from '../../../database/mind-sweeps/write';
import EditTitleModal from './edit-title.modal';


function MindsweepTopBar() {
  const { mindSweepKey } = useParams();
  const { loading, data: mindSweep } = useContext(CurrentMindSweepContext);
  const history = useHistory();
  const { title, page, exists } = mindSweep || {};

  const [editTitle, setEditTitle] = useState(false);

  return (
    <>
      {
        !!editTitle
        && <EditTitleModal title={title} setEditTitle={setEditTitle} />
      }
      <div>
        <div className="h-12 bg-teal-700 text-white xl:bg-white shadow xl:text-gray-700 flex w-full items-center">
          <div
            className="tex-left flex-grow leading-none focus:outline-none flex items-center p-3 truncate"
          >
            <Link
              to={
                page === 'archived'
                  ? '/archive'
                  : '/'
              }
            >
              <i className="fas fa-arrow-left mr-5"></i>
            </Link>
            <h1
              className="w-full leading-none font-bold truncate"
              onClick={() => {
                setEditTitle(true);
              }}
            >
              {
                !!title
                && title
              }
              {
                !title
                && !!loading
                && (
                  <span className="text-gray-400 font-normal">...</span>
                )
              }
              {
                !title
                && !loading
                && (
                  <span className="text-gray-500 font-normal">Add title</span>
                )
              }
            </h1>
          </div>
          {
            !loading
            && !!exists
            && (
              <button
                type="button"
                className="ml-auto p-4 leading-none focus:outline-none"
                onClick={() => {
                  if (page === 'archived') {
                    setMindSweep({
                      mindSweepKey,
                      fields: {
                        page: 'inbox',
                      },
                    });
                    return;
                  }

                  setMindSweep({
                    mindSweepKey,
                    fields: {
                      page: 'archived',
                    },
                  });
                }}
              >
                {
                  page === 'archived'
                  && (
                    <i className="fas fa-archive"></i>
                  )
                }
                {
                  page !== 'archived'
                  && (
                    <i className="far fa-archive"></i>
                  )
                }
              </button>
            )
          }
          {
            page === 'archived'
            && (
              <button
                type="button"
                className="p-4 leading-none focus:outline-none"
                onClick={() => {
                  const sure = window.confirm('Are you sure?');
                  if (!sure) {
                    return;
                  }
                  deleteMindSweep({ mindSweepKey }).then(() => {
                    history.replace('/archive');
                  })
                }}
              >
                <i className="far fa-trash"></i>
              </button>
            )
          }
          <button
            type="button"
            className="flex-no-shrink p-4 leading-none focus:outline-none xl:hidden"
            onClick={() => {
              logout();
            }}
          >
            <i className="far fa-sign-out"></i>
          </button>
        </div>
      </div>
    </>
  );
}


export default MindsweepTopBar;
